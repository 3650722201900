import { AuthenticationContext, adalFetch, withAdalLogin } from "react-adal";

export const adalConfig = {
  clientId: "2648d652-0325-425a-88a4-fd99068b32a8",
  endpoints: {
    api: "https://graph.microsoft.com",
  },
  cacheLocation: "localStorage",
  redirectUri: "https://reports.heedify.io/",
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(
  authContext,
  adalConfig.endpoints.api
);

